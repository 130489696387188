@font-face {
  font-family: "Exo2";
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url("assets/fonts/Exo2-Regular.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Exo2 Medium";
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url("assets/fonts/Exo2-Medium.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Exo2 SemiBold";
  font-weight: 600;
  font-style: normal;
  font-display: block;
  src: url("assets/fonts/Exo2-SemiBold.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Exo2 Bold";
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url("assets/fonts/Exo2-Bold.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Exo2 Black";
  font-weight: 900;
  font-style: normal;
  font-display: block;
  src: url("assets/fonts/Exo2-Black.ttf") format("truetype");
  font-display: block;
}
