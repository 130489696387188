*, *:before, *:after {
  box-sizing: border-box;
}

.mainContent {
  padding: 20px 124px;
  @media (max-width: 1280px) {
    padding: 20px 80px;
  }
  @media (max-width: 960px) {
    padding: 20px 40px;
  }
  @media (max-width: 900px) {
    padding: 20px 20px;
  }
  @media (max-width: 600px) {
    padding: 20px 20px;
  }
}
